<template>
    <CDropdown
        inNav
        class="c-header-nav-items"
        placement="bottom-end"
        add-menu-classes="pt-0"
    >
        <template #toggler>
            <CHeaderNavLink>
                <div class="c-avatar">
                    <img
                        src="img/user_profile.png"
                        class="c-avatar-img "
                    />
                </div>
            </CHeaderNavLink>
        </template>
        <CDropdownHeader tag="div" class="text-center" color="light">
            <strong>{{$t('account')}}</strong>
        </CDropdownHeader>
        <CDropdownItem>
            <CIcon name="cil-user" /> {{$t('profile')}}
        </CDropdownItem>
        <CDropdownItem>
            <CIcon name="cil-lock-locked" /> {{$t('logout')}}
        </CDropdownItem>
    </CDropdown>
</template>

<script>
export default {
    name: 'TheHeaderDropdownAccnt',
}
</script>

<style scoped>
.c-icon {
    margin-right: 0.3rem;
}
</style>
